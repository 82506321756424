.timegrid {
    position:relative;
}
.timegrid.loading svg{
    opacity: .5;
}
.timegrid rect.day {
    fill: $primary;
    opacity: .6;
}
.timegrid rect.day.blocked {
    fill: $danger;
}
.timegrid rect.day:hover{
    transform: scale(1.05);
    transition: all .2s ease-in-out;
    filter: brightness(1.3) drop-shadow(0px 0px 2px $danger);
    opacity: 1;
}
.timegrid .label {
    font-size: .6rem;
    fill: $light;
}
[data-bs-theme="light"] .timegrid .label {
    fill: $dark;
}
.timegrid .spinner {
    position:absolute;
    top:calc(50% - 10px);
    left:calc(50% - 40px);
}