.table.plan {
    transition: filter .3s ease-out;
}

.loading .table.plan {
    filter: blur(4px);
}
.busy .table.plan {
    filter: blur(4px) saturate(0);
}
.table.plan td {
    width: 2.5rem;
    min-width: 2.5rem;
    background-clip: padding-box;
}

.table.plan td.link {
    padding: 0;
    position: relative;
}

.table.plan td.link {
    background-color: transparent;
}

.table.plan td.reserved {
    background-color: $warning;
}

.table.plan td.blocked {
    background-color: $warning;
    filter: sepia(40%) brightness(60%);
}

.table.plan td.incart {
    background-color: $info;
}

.table.plan td.blocked.incart,
.table.plan td.reserved.incart {
    background-color: $danger;
}

.table.plan td.link a {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    white-space: pre-wrap;
    opacity: .8;
}

.table.plan th.name {
    width: 13rem;
    min-width: 13rem;
}

table.plan th.today {
    text-shadow: 0 0 6px $secondary, 0 0 12px $danger;
}

.table.plan td.link:not(.blocked):hover {
    transform: scale(1.06);
    transition: all .2s ease-in-out;
    filter: brightness(1.3) drop-shadow(0px 0px 2px $warning);
    opacity: 1;
    z-index: 5;
}