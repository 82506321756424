@import "./Fonts.scss";
$web-font-path: false;
@import "~bootswatch/dist/yeti/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/yeti/bootswatch";

@import "./Animations.scss";
@import "./TimeGrid.scss";
@import "./Planner.scss";


body {
    background: linear-gradient(165deg, #fff 0%, #6dc9e7 85%, #1c9ec9 100%);
}

[data-bs-theme="dark"] body {
    background: linear-gradient(165deg, #000000 0%, #15262c 85%, #093646 100%);
}

[data-color-mode*="dark"] .w-md-editor-toolbar,
[data-color-mode*="dark"] .wmde-markdown,
[data-color-mode*="dark"] .wmde-markdown-var,
.wmde-markdown-var[data-color-mode*="dark"],
.wmde-markdown[data-color-mode*="dark"],
body[data-color-mode*="dark"] {
    background-color: #222;
}

.navbar {
    background: linear-gradient(225deg, #6dc9e7 85%, #1c9ec9 100%);
}

[data-bs-theme="dark"] .navbar {
    background: linear-gradient(225deg, #15262c 85%, #093646 100%);
}

.modal-backdrop.show {
    backdrop-filter: sepia(70%) blur(5px);
    background: transparent;
}

button.link {
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
    background: transparent;
    border: 0;
}

a,
button.link {
    text-decoration: none;
    transition: all .4s ease-in-out;
}

a:hover,
button.link:hover {
    color: $danger;
}

#root,
.fullscreen {
    min-height: 100vh;
}

.text-success {
    color: $success;
}

.btn-xs,
.btn-group-xs>.btn {
    --bs-btn-padding-y: 0.15rem;
    --bs-btn-padding-x: 0.33rem;
    --bs-btn-font-size: 0.7rem;
}

.btn-order-toggle {
    background: transparent;
    border: 0;
    font-weight: bold;
    color: $primary;
    padding-left: 0;
}

.btn-order-toggle:hover {
    color: lighten($primary, 20%);
}

.btn-order-toggle::after {
    color: lighten($primary, 20%);
    margin-left: .5rem;
    font-size: .8rem;
}

.btn-order-toggle.asc::after {
    content: '▼';
}

.btn-order-toggle.desc::after {
    content: '▲';
}

.form-floating>textarea.form-control {
    height: auto;
}

#disclaimer {
    position: fixed;
    bottom: 2rem;
    width: 40rem;
    left: calc(50% - 20rem);
    background: $secondary;
    border: 1px solid $primary;
    z-index: 9;
}

[data-bs-theme="dark"] #disclaimer {
    background: #222;
}

#disclaimer a {
    text-decoration: underline;
}

#disclaimer .form-check-input[disabled]~.form-check-label,
#disclaimer .form-check-input:disabled~.form-check-label {
    opacity: 1;
}

button.textlink {
    background: transparent;
    border: 0;
}

#disclaimer h1,
#disclaimer h2,
#disclaimer h3 {
    font-weight: bold;
    color: #fff;
}

.inlines .form-control {
    display: inline-block;
    width: auto;
}

.loader {
    background: $primary !important;
    color: $primary !important;
}

.loader>div {
    box-shadow: $primary 0px 0px 10px, $primary 0px 0px 10px !important;
}

.pointer {
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1)) !important;
    cursor: pointer;
}

.dropzone {
    border: dashed 1px $primary;
}

.dropzone.is-disabled {
    filter: saturate(0.5) opacity(0.5);
}

.interactable {
    cursor: pointer;
}

.adminimage {
    position: relative;
}

.adminimage.danger .img-fluid {
    box-shadow: 0px 0px 10px 3px rgba($danger, 0.9);
}

.card-img-top {
    width: 100%;
    height: 20rem;
    object-fit: cover;
}

.imagecontext {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: .25rem;
}

a .card,
.hover-zoom {
    transition: all .15s ease-in-out;
}

a:hover .card {
    transform: scale(1.05);
}

.hover-zoom:hover {
    transform: scale(1.02);
}

.pagination .page-link {
    margin: 0 .1rem !important;
}

.pagination .page-item.disabled .page-link {
    background-color: var(--bs-pagination-hover-bg);
}

.text-small {
    font-size: 80%;
}

.cart-pill {
    font-weight: 800;
    position: absolute;
    bottom: -.15rem;
    right: -.25rem;
    scale: .7;
    padding: .35rem .5rem;
    border-radius: 1rem !important;
}

.cart-pill .spinner-border {
    width: .75rem;
    height: .75rem;
}

.cart-modal .modal-dialog {
    max-width: 990px;
}

.cart-modal .btn-close {
    margin-left: 1rem;
}

.cart-modal .table tr.unavailable {
    box-shadow: 0px 0px 10px 3px rgba($danger, 0.9);
}

.relative {
    position: relative;
}

.readonly {
    opacity: .7;
}

.table>tbody>tr.late>td {
    color: $warning;
}

.table>tbody>tr.overdue>td {
    color: $danger;
}

.table>tbody>tr.done>td,
.table>tbody>tr.systemuser>td {
    opacity: .7;
}

.table>tbody>tr.storno>td {
    opacity: .6;
    font-style: italic;
}

.table .btn-xs {
    min-width: 2rem;
}

.vmiddle {
    vertical-align: middle;
}